import Cookies from "js-cookie";
import {
  API_BASE_URL,
  API_BASE_URL_LOGIN,
  SUPER_ADMIN_RESOURCES,
  isMobile,
  HEADER_SUBSCRIPTION_KEY,
} from "./global";
import { alphabetSort, numberSort, replaceFalsyInArray } from "../../utils/utils";

// Function to get the JWT token from the cookie
export const getToken = () => Cookies.get("token");

export async function validateToken(token) {
  const response = await fetch(`${API_BASE_URL}/validate-token/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(token),
  });

  if (response.status === 403) {
    throw new Error("No Access");
  }

  if (!response.ok) {
    throw new Error("Login failed");
  }

  const data = await response.json();
  return data;
}

export async function checkAccess(resourceKey, restrictMobile = false) {
  const { resources, role } = await validateToken(Cookies.get("token"));
  let completeResources =
    role.type === "admin"
      ? [...resources, ...SUPER_ADMIN_RESOURCES]
      : resources;
  return (
    completeResources.some((resource) => resource.key === resourceKey) &&
    (!restrictMobile || !mobileRestriction())
  );
}

export function mobileRestriction() {
  return window.innerWidth < isMobile;
}

export async function loginUser(username, password) {
  const response = await fetch(`${API_BASE_URL_LOGIN}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
    },
    body: JSON.stringify({ username, password }),
  });

  if (response.status === 403) {
    throw new Error("No Access");
  }

  if (!response.ok) {
    throw new Error("Login failed");
  }

  const data = await response.json();
  return data;
}

export async function getEmployeesSchedule(locationId, initialDate, endDate) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  const response = await fetch(
    `${API_BASE_URL}/get-employees-schedule?locationId=${locationId}&initialDate=${initialDate}&finalDate=${endDate}`,
    {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status === 403) {
    throw new Error("No Access");
  }

  if (!response.ok) {
    throw new Error("Something went wrong when getting user shifts");
  }

  const data = await response.json();
  return {
    ...data,
    load: {
      ...data.load,
      groups: numberSort({
        array: data.load?.groups?.map(group => ({
          ...group,
          employees: alphabetSort({
            array: replaceFalsyInArray(group.employees, 'employee.userName', 'employee.employeeName'),
            properties: ['employee.userName']
          })
        })) ?? [],
        properties: ['groupOrder'],
        keepTop: (crew => crew.id === 'transfer'),
        keepLast: (crew => crew.id === null)
      })
    }
  };
}

export async function getEmployeesScheduleFromMonthYear(
  locationId,
  year,
  monthId
) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  const response = await fetch(
    `${API_BASE_URL}/get-week-schedule?locationId=${locationId}&year=${year}&month=${monthId}`,
    {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status === 403) {
    throw new Error("No Access");
  }

  if (!response.ok) {
    throw new Error("Something went wrong when getting user shifts");
  }

  const data = await response.json();
  return {
    ...data,
    load: {
      ...data.load,
      groups: numberSort({
        array: data.load.groups.map(group => ({
          ...group,
          employees: alphabetSort({
            array: replaceFalsyInArray(group.employees, 'employee.userName', 'employee.employeeName'),
            properties: ['employee.userName']
          })
        })),
        properties: ['groupOrder'],
        keepTop: (crew => crew.id === 'transfer'),
        keepLast: (crew => crew.id === null)
      })
    }
  };
}

export async function getScheduleTimes() {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  const response = await fetch(`${API_BASE_URL}/get-schedule-times`, {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Something went wrong when getting schedule times");
  }

  const data = await response.json();
  return data;
}

export async function getSites() {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  const response = await fetch(`${API_BASE_URL}/get-sites`, {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Something went wrong when getting sites");
  }

  const data = await response.json();
  return data;
}

export async function deleteUserShift(id) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  const response = await fetch(
    `${API_BASE_URL}/delete-employee-shift?shiftId=${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Something went wrong when deleting user");
  }

  const data = await response.json();
  return data;
}

export async function updateEmployeeShift(data) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  if (data.id === null) {
    let shiftList = data.shiftDate.map(
      date => ({
        ...data,
        shiftDate: date
      })
    );
    const response = await fetch(`${API_BASE_URL}/create-employee-shift`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(shiftList),
    });
    if (!response.ok) {
      throw new Error("Something went wrong! Couldn't create employeee shift");
    }
    const loadedData = await response.json();
    return loadedData;
  } else {
    const response = await fetch(`${API_BASE_URL}/update-employee-shift`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({...data, shiftDate: data.shiftDate[0]}),
    });
    if (!response.ok) {
      throw new Error("Something went wrong! Couldn't create employeee shift");
    }
    const loadedData = await response.json();
    return loadedData;
  }
}

export async function createEmployeeTransfer(data) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  const response = await fetch(`${API_BASE_URL}/create-employee-transfer`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error("Something went wrong! Couldn't create transfer");
  }
  const loadedData = await response.json();
  return loadedData;
}

export async function cancelEmployeeTransfer(id, date) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }
  const params = new URLSearchParams();
  params.append('transferId', id);
  if (date) params.append('date', date);

  try {
    const response = await fetch(`${API_BASE_URL}/delete-employee-transfer?${params.toString()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Login failed");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
}

export async function createEmployeeTransferDeleteShift(data) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  const response = await fetch(
    `${API_BASE_URL}/create-employee-transfer-delete-shifts`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );
  if (!response.ok) {
    throw new Error("Something went wrong! Couldn't delete employeee shift");
  }
  const loadedData = await response.json();
  return loadedData;
}

export async function sendEmailForgotPassword(email) {
  const response = await fetch(
    `${API_BASE_URL_LOGIN}/reset-password?email=${email}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Something went wrong! Couldn't send email");
  }
  const loadedData = await response.json();
  return loadedData;
}

export async function recoverEmailPassword(data) {
  const response = await fetch(`${API_BASE_URL_LOGIN}/recover-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error("Something went wrong! Couldn't send email");
  }
  const loadedData = await response.json();
  return loadedData;
}