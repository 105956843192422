import React, { useRef, useEffect, Fragment, useCallback, useState } from 'react';
import classes from './InfiniteScroll.module.scss'
import UserCard from '../Users/UserCard';
import { useSelector, useDispatch } from 'react-redux';
import { loading, setLoading } from '../../features/loadingSlice';
import MonthViewDays from './MonthViewDays';
import { fetchWeek, monthViewErrorState } from '../../features/monthViewSlice';
import { currentLocationIdState } from '../../features/currentDataSlice';
import { showToast } from '../../features/toastNotificationSlice';
import { getWeekMonthDays, transformDateTimeZone } from '../services/dateTimeService';

const InfiniteScroll = (props) => {
  const rowHeaderWidth = Math.max(17, (260 / window.innerWidth) * 100);
  const placeholderCount = 7;
  const columnWidth = Math.max((100 - rowHeaderWidth) / 30, (30 / window.innerWidth) * 100);
  const locationId = useSelector(currentLocationIdState);
  const isLoading = useSelector(loading);
  const scrollEventRef = useRef(false);
  const scrollArea = useRef(null);
  const dispatch = useDispatch();
  const error = useSelector(monthViewErrorState);
  const [gridData, setGridData] = useState({
    groups: props.users ?? [],
    weekDays: getWeekMonthDays(
      transformDateTimeZone((((props.users ?? [])[0]?.employees ?? [])[0]?.employee?.shifts ?? [])[0]?.shiftDate ?? "", ""),
      (((props.users ?? [])[0]?.employees ?? [])[0]?.employee?.shifts ?? []).length
    )
  })

  const addWeek = (head) => {
    let referenceShifts = gridData.groups[0].employees[0].employee.shifts;
    const referenceDate = new Date(referenceShifts[head ? 0 : referenceShifts.length - 1].shiftDate);
    const startDate = new Date(referenceDate);
    startDate.setDate(startDate.getDate() + (head ? - 7 : 1));
    const endDate = new Date(referenceDate);
    endDate.setDate(endDate.getDate() + (head ? -1 : 7));
    let startDateString = startDate.toISOString().slice(0, 10);
    let endDateString = endDate.toISOString().slice(0, 10);
    dispatch(fetchWeek({ locationId, startDateString, endDateString, head }));
  }

  const checkUpdateMonth = useCallback(() => {
    if (gridData.weekDays.length > 0) {
      let shownDays = gridData.weekDays.filter(
        (day, i) =>
          ((columnWidth * window.innerWidth / 100) * (i + placeholderCount) > scrollArea.current.scrollLeft - 4) &&
          ((columnWidth * window.innerWidth / 100) * (i + placeholderCount + 1) < scrollArea.current.scrollLeft + (scrollArea.current.clientWidth - window.innerWidth * rowHeaderWidth / 100) + 10)
      );
      let months = [...new Set(shownDays.map(d => d.month))].map(m => { return { month: m, count: shownDays.filter(x => x.month === m).length } })
      let years = [...new Set(shownDays.map(d => d.year))].map(y => { return { year: y, count: shownDays.filter(x => x.year === y).length } })
      let month = months.reduce((max, current) => (current.count > max.count ? current : max), months[0]).month;
      let year = years.reduce((max, current) => (current.count > max.count ? current : max), years[0]).year;
      props.updateMonth(month);
      props.updateYear(year);
    }
  }, [columnWidth, gridData, props])

  const resetScrollToLeft = useCallback(() => {
    scrollEventRef.current = true;
    if (scrollArea.current) {
      const firstDataElement = scrollArea.current.children[placeholderCount + 1];
      if (firstDataElement) {
        let count = Math.min(props.displayedCount || 35, 35);
        let columnW = Math.max(((100 - rowHeaderWidth) / count), columnWidth);
        scrollArea.current.scrollLeft = (columnW / 100) * window.innerWidth * placeholderCount;
      }
    }
  }, [props.displayedCount, columnWidth]);

  useEffect(() => {
    if (error !== null) {
      dispatch(setLoading(false));
      dispatch(showToast({ type: 'warning', message: 'Something went wrong' }));
      console.log(error);
    }
  }, [error, dispatch])

  useEffect(() => {
    resetScrollToLeft();
    localStorage.setItem('loadingHead', 'false');
    localStorage.setItem('loadingTail', 'false');
  }, [resetScrollToLeft]);

  useEffect(() => {
    dispatch(setLoading(true));
    let newData = {
      groups: props.users ?? [],
      weekDays: getWeekMonthDays(
        transformDateTimeZone((((props.users ?? [])[0]?.employees ?? [])[0]?.employee?.shifts ?? [])[0]?.shiftDate ?? "", ""),
        (((props.users ?? [])[0]?.employees ?? [])[0]?.employee?.shifts ?? []).length
      )
    }
    if ((localStorage.getItem('lastUpdate') === 'head')) { resetScrollToLeft() }
    if (newData.weekDays.length === 35) {
      resetScrollToLeft();
    }
    setGridData(newData);
    dispatch(setLoading(false));
  }, [props.users, setGridData, dispatch, resetScrollToLeft]);

  useEffect(() => {
    checkUpdateMonth();
  }, [gridData, checkUpdateMonth]);

  const loadDataOnScroll = () => {
    if (!scrollEventRef.current) {
      if (!isLoading) {
        if (!(localStorage.getItem('loadingHead') === 'true')) {
          if (scrollArea.current.scrollLeft < 5) {
            localStorage.setItem('loadingHead', 'true');
            addWeek(true);
          }
        }
        if (!(localStorage.getItem('loadingTail') === 'true')) {
          if (scrollArea.current.scrollLeft > (scrollArea.current.scrollWidth - scrollArea.current.clientWidth) - 5) {
            localStorage.setItem('loadingTail', 'true');
            addWeek(false);
          }
        }
      }
    } else {
      scrollEventRef.current = false;
    }

  }

  const scrollHandler = () => {
    loadDataOnScroll();
    checkUpdateMonth();
  }

  const placeholders = (sticky = false) => Array.from({ length: placeholderCount }, (_, index) =>
    <div className={`${classes.placeholder} ${sticky ? classes.stickyPlaceholder : ''}`} key={index}>
      <div className={classes.placeholderContent}></div>
    </div>
  )

  const groupRow = (name, site) => [
    <div className={`${classes.groupHeader} ${classes.fixedLeft}`} key={0}>{name === 'Users Transfers' && <span className={`icon-transfer-user ${classes.transferIcon}`}></span>}
    {`${name}${site ? ` - ${site}` : ''}`}
    </div>,
    ...Array.from({ length: gridData.weekDays.length + 2 * placeholderCount }, (_, i) => <div className={classes.groupEntry} key={i + 1}></div>),
  ];

  const getMonthCount = () => {
    return gridData.weekDays.reduce((accumulator, { month }) => {
      const existingMonth = accumulator.find(item => item.name === month);
      existingMonth ? existingMonth.count++ : accumulator.push({ name: month, count: 1 });
      return accumulator;
    }, []);
  }

  const monthRow = () => [
    <div className={classes.monthRowElement} key={1}></div>,
    <div className={classes.monthRowElement} style={{ gridColumn: `span ${placeholderCount}` }} key={2}></div>,
    getMonthCount().map((m, index) =>
      <div key={index+3} className={classes.monthNameContainer} style={{ gridColumn: `span ${m.count}` }}>
        <div className={classes.monthName}>
          <span className={classes.monthText}>{m.name}</span>
        </div>
      </div>
    ),
    <div key={0} className={classes.monthRowElement} style={{ gridColumn: `span ${placeholderCount}` }}></div>
  ]


  return ((props.users ?? []).length > 0 &&
    <div className={classes.grid} style={{ gridTemplateColumns: `${rowHeaderWidth*window.innerWidth/100}px repeat(${gridData.weekDays.length + 2 * placeholderCount}, ${columnWidth * window.innerWidth/100}px)` }} ref={scrollArea} onScroll={scrollHandler}>
      {monthRow()}
      <div className={`${classes.gridHeader} ${classes.fixedLeft} ${classes.gridTitle}`}>AssignedEmployees</div>

      {/* Days Header */}
      {placeholders(true)}

      {gridData.weekDays?.map((day, index) =>
        <div className={`
        ${classes.gridHeader} ${classes.columnHeader} 
        ${index % 2 ? classes.greyBackground : ''} 
        ${day.isToday === true ? classes.isToday : ""}
        ${index % 14 === 0 ? classes.divisorLine : ''}`} key={index}>
          <span className={`${classes.dayNumber} ${day.isToday === true ? classes.isToday : ""}`}>{day.day}</span>
          <span className={classes.dayName}>{day.name}</span>
        </div>)
      }
      {placeholders(true)}
 
      {gridData.groups?.map((userGroup, index) =>
        <Fragment key={index}>
          {groupRow(userGroup.groupName, userGroup.site)}
          {userGroup.employees?.map((employee, index) => {
            return (
              <Fragment key={index}>
                <div className={`${classes.fixedLeft} ${classes.rowHeader} ${index % 2 ? classes.greyBackground : ''} ${employee.employee.active ? '':classes.disabledRow}`}>
                  <UserCard
                    key={employee.employee.id}
                    className={"contactCardMonthView"}
                    employee={employee.employee}
                    showfilterChecked={false}
                    showMoveIcon={false}
                    showEmployeeCard={true}
                    isTransfer={userGroup.id === 'transfer'}
                    userTransfers={employee}
                  />
                </div>
                {placeholders()}
                <MonthViewDays
                  key={index}
                  numColumns={employee.employee.shifts.length}
                  columnWidth={columnWidth}
                  shifts={employee.employee}
                  greyBackground={index % 2}
                  isUserTransfer={userGroup.id === 'transfer'}
                  locationId={userGroup.locationId ?? employee.fromLocation}
									disabled={!employee.employee.active}
                />
                {placeholders()}
              </Fragment>
            )
          })}
        </Fragment>
      )}
    </div>
  );
}

export default InfiniteScroll;