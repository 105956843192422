import { Fragment, useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../UI/Modal";
import classes from "./WorkShiftConfig.module.scss";
import moment from "moment";
import userRegisterContext from "../store/user-register-context";
import {
  updateEmployeeShift,
  deleteUserShift,
} from "../services/userRegisterService";
import Dropdown from "../UI/Dropdown";
import DeleteConfirmationDialog from "../UI/DeleteConfirmationDialog";
import TransferUser from "./TransferUser";
import { UserInformationDetail } from "./UserInformationDetail";
import {
  deleteShiftUpdateCurrentData,
  addNewShiftUpdateCurrentData,
} from "../../features/monthViewSlice";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import * as React from "react";
import dayjs from "dayjs";
import AddComments from "./AddComments";
import {
  resourcePermissions,
  checkPermission,
} from "../../features/resourcePepermissionsSlice";
import { MONTHVIEW_ID, MONTH_VIEW } from "../services/global";
import {
  allLocations,
  selectedLocationState,
  selectedMonthState,
  selectedYearState,
} from "../../features/currentDataSlice";
import { useLocation } from "react-router-dom";
import useShiftInfo from "../hooks/useShiftInfo";
import useShiftForm from "../hooks/useShiftForm";
import Checkbox from "../UI/Checkbox";
import Input from "../UI/Input";
import ScheduleLabel from "./ScheduleLabel";

const WorkShiftConfig2 = ({
  shift,
  shiftIndex,
  onClose,
  user,
  showTransferUser,
  openFrom,
}) => {
  const userRegisterCtx = useContext(userRegisterContext);
  const [showDialog, setShowDialog] = useState(false);
  const selectedLocation = useSelector(selectedLocationState);
  const selectedMonth = useSelector(selectedMonthState);
  const selectedYear = useSelector(selectedYearState);
  const location = useLocation();
  const dispatch = useDispatch();
  const locations = useSelector(allLocations);
  const currentLocation = locations.find(l => l.id === selectedLocation.id);

  const { shiftState, locationSites, locationSchedules, updateShift } = useShiftForm({shift, currentLocation});

  const [showEditableShift, setShowEditableShift] = useState(false);

  useEffect(() => {
    setShowEditableShift((shift.specialInitialTime || shift.specialFinalTime)?true:false);
    handleUserChange(user);
  }, [shift])

  const handleSelectedSite = (site) => {
    updateShift('site', site);
    const selectedSiteSchedules = locationSchedules?.[site?.id] ?? [];
    const newSchedule = selectedSiteSchedules[0] ?? null
    updateShift('scheduleTime', newSchedule);
  };

  const handleSelectedSchedule = (time) => {
    updateShift('scheduleTime', time);
  };

  const onCancel = () => {
    setShowDialog(false);
  };

  const onConfirm = async () => {
    try {
      const response = await deleteUserShift(shift.id);
      if (response.responseCode === 200) {
        if (location.pathname !== MONTH_VIEW) {
          fetchCurrentData();
        } else {
          dispatch(
            deleteShiftUpdateCurrentData({ shiftId: shift.id, userId: user.id })
          );
          onClose();
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const deleteShift = () => {
    setShowDialog(true);
  };

  const fetchCurrentData = () => {
    userRegisterCtx.fetchWeekData(
      selectedLocation,
      userRegisterCtx.selectedWeek.startDate.toISOString().slice(0, 10),
      userRegisterCtx.selectedWeek.endDate.toISOString().slice(0, 10),
      selectedYear,
      selectedMonth
    );
    onClose();
  };

  const handleUserChange = (newUser) => {
    updateShift('employee', {id: newUser.id});
  };

  const handleSelectedShiftDate = (date) => {
    updateShift('shiftDate', date.map(d => d.date.format()));
  };

  const currentResourcePermissions = useSelector(
    resourcePermissions(openFrom === "month-view" ? "month-view" : "week-view")
  );

  const editShiftPerrmission = checkPermission(currentResourcePermissions, 'edit-shift');

  const handleOvertimeSelection = (value) => {
    updateShift('overtime', value);
  };

  const handleTimeChange = (e) => {
    const {name, value} = e.target;
    updateShift(name, value);
  };

  const handleTextAreaChange = ( event) => {
    updateShift('observationTime', event.target.value);
  };

  const handleEditableShiftTime = () => {
    setShowEditableShift(prev => {
      if (prev === true){
        updateShift('specialInitialTime', '');
        updateShift('specialFinalTime', '');
        updateShift('observationTime', '');
      } 
      return !prev
    });
  };

  const saveShiftConfig = async () => {
    console.log('*shiftstate', shiftState);
    let data = {
      ...shiftState,
      master: location.pathname !== MONTH_VIEW ? {id: userRegisterCtx.selectedWeek.masterId} : {id: 0},
      locationId: location.pathname !== MONTH_VIEW ? null : selectedLocation.id,
    };
    try {
      const responseData = await updateEmployeeShift(data);
      if (responseData.load) {
        if (location.pathname !== MONTH_VIEW) {
          fetchCurrentData();
        } else {
          dispatch(
            addNewShiftUpdateCurrentData({
              userId: user.id,
              shiftIndex,
              response: responseData.load[0],
            })
          );
          onClose();
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const siteSchedules = locationSchedules?.[shiftState?.site?.id] ?? [];

  return (
    <Fragment>
      {!showDialog && (
        <Modal onClose={onClose} className={"overflowAuto"} id={"workShiftConfig"}>
          <div className={classes.container}>
            <div className={classes.dateInfoContainer}>
              {shift.id && (
                <span className={classes.title}>Edit work shift</span>
              )}
              {shift.id === null && (
                <span className={classes.title}>Create work shift</span>
              )}
              <span className={classes.date}>{moment.utc(shiftState?.shiftDate).format("MMMM, Do, YYYY")}</span>
            </div>
            <div className={classes.buttonContainer}>
              {checkPermission(currentResourcePermissions, "delete-shift") &&
                shift.id && (
                  <button id="deleteShiftButton" onClick={deleteShift}>
                    <span
                      className={`icon-delete ${classes.iconDelete}`}
                    ></span>
                  </button>
                )}
              <button className={classes.closeModal} onClick={onClose}>
                <span className={`icon-close ${classes.iconClose}`}></span>
              </button>
            </div>
          </div>
          <span className={`${classes.container} ${classes.userInfoTitle}`}>
            Selected Employee
          </span>
          <UserInformationDetail employee={user} />
          <div className={classes.siteContainer}>
            <span className={classes.dropdownTitle}>Site</span>
            <Dropdown
              className="sitesDropdown"
              dropdownContainer="sitesDropdownContainer"
              options={locationSites}
              defaultValue={shiftState.site}
              onClick={handleSelectedSite}
              showUserInfo={false}
              showSearch={false}
              disabled={(!editShiftPerrmission) || !(locationSites?.length > 0)}
              disabledPlaceholder={editShiftPerrmission ? 'No active sites in location' : null}
              windowId={"workShiftConfig"}
              displayProperty={"name"}
            ></Dropdown>
          </div>

          <div className={classes.siteContainer}>
            <span className={classes.dropdownTitle}>Time slot</span>

            <Dropdown
              className="sitesDropdown"
              dropdownContainer="sitesDropdownContainer"
              options={siteSchedules}
              defaultValue={shiftState.scheduleTime}
              icon={"icon-check"}
              iconClass={"checkIcon"}
              onClick={handleSelectedSchedule}
              showUserInfo={false}
              showSearch={false}
              getLabel={ScheduleLabel}
              disabled={(!editShiftPerrmission) || !(siteSchedules?.length > 0)}
              disabledPlaceholder={editShiftPerrmission ? (siteSchedules?.length > 0 ? '' : (shiftState.scheduleTime ? '':'No active schedules in site')) : null}
              windowId={"workShiftConfig"}
              displayProperty={"label"}
            ></Dropdown>
          </div>

          {showTransferUser && (
            <TransferUser
              user={user}
              shift={shift}
              handleUserChange={handleUserChange}
              handleSelectedShiftDate={handleSelectedShiftDate}
              disabled={
                !editShiftPerrmission
              }
            ></TransferUser>
          )}
          <div className={classes.overtimeCheckContainer}>
            <div
              className={`${showEditableShift === true
                  ? `${classes.isItemSelected} ${classes.overtime}`
                  : `${classes.overtime}`
                }`}
            >
              <Checkbox checked={showEditableShift} onChange={handleEditableShiftTime}>
                Edit Shift Times
              </Checkbox>
              
            </div>
            <div
              className={`${shiftState.overtime === true
                  ? `${classes.isItemSelected} ${classes.overtime}`
                  : `${classes.overtime}`
                }`}
            >
              <Checkbox checked={shiftState.overtime === true} onChange={handleOvertimeSelection}>
                Overtime
              </Checkbox>

              
            </div>
          </div>
          {showEditableShift ? (
            <div className={classes.editTimeInfo}>
              <div className={classes.dropdownsPosition}>
                <Input name={"specialInitialTime"} value={shiftState.specialInitialTime} label={"Initial Time"} onChange={handleTimeChange} disabled={!editShiftPerrmission} type="time"/>
                <Input name={"specialFinalTime"} value={shiftState.specialFinalTime} label={"Final Time"} onChange={handleTimeChange} disabled={!editShiftPerrmission} type="time"/>
              </div>
              <Input 
                type="area" 
                value={shiftState.observationTime} 
                onChange={handleTextAreaChange} 
                disabled={!editShiftPerrmission} 
                placeholder={"Reason for time change"}
                showCount={true} 
                maxLength={150}
                maxLengthMessage = {"You have reached the maximum length for this observation."}
              />
            </div>
          ) : null}

          <div className={classes.saveBtnContainer}>
            {checkPermission(currentResourcePermissions, "create-shift") &&
              shift.id === null && (
                <button onClick={saveShiftConfig} id="createShiftButton">
                  Create
                </button>
              )}
            {editShiftPerrmission &&
              shift.id && (
                <button onClick={saveShiftConfig} id="saveShiftButton">
                  Save
                </button>
              )}
            {editShiftPerrmission &&
              shift.id && <AddComments shiftId={shift.id} />}
          </div>
        </Modal>
      )}
      {showDialog && (
        <DeleteConfirmationDialog
          message="Are you sure you want to delete the shift?"
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      )}
    </Fragment>
  );
};

export default WorkShiftConfig2;