import Cookies from "js-cookie";
import { API_BASE_URL, HEADER_SUBSCRIPTION_KEY } from "./global";

// Function to get the JWT token from the cookie
export const getToken = () => Cookies.get("token");

export async function saveLeaveReason(reasons) {
  const token = getToken();
  const url = API_BASE_URL + "/save-employment-leaves";
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reasons),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function deleteLeaveReason(reasonId) {
  const token = getToken();
  const url = API_BASE_URL + `/delete-employment-leave?id=${reasonId}`;
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}
