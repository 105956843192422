import React, { useState } from "react";
import classes from "./LeaveReasonAdmin.module.scss";
import { deleteLeaveReason, saveLeaveReason } from "../services/leaveAdminService";
import InformationDialog from "../UI/InformationDialog";
import SwitchButton from "../UI/switchButton";
import MultiOptionDialog from "../UI/MultiOptionDialog";
import { useDispatch } from "react-redux";
import { showToast } from "../../features/toastNotificationSlice";

const LeaveReasonAdmin = ({
  leaveState,
  setLeaveStateHandler,
  updateLeaveState,
  fetchLeavesHandler,
  fetchLeaves,
  setLeaveState
}) => {
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();


  const handleReasonChange = (index, value) => {
    const updatedLeaveState = { ...leaveState };
    updatedLeaveState.leaves[index].name = value;
    updateLeaveState(updatedLeaveState);
  };

  const addNewReasonHandler = () => {
    const newLeave = {
      id: null,
      label: "",
      name: "",
      type: leaveState.type,
    };
    setLeaveStateHandler(newLeave);
  };

  const saveReasonHandler = async () => {
    try {
      const response = await saveLeaveReason(leaveState.leaves);
      if (response.responseCode === 200) {
        leaveState.leaves = response.load;
        setMessage(leaveState.successSaveMessage ?? "Leave reason saved!");
      } else {
        setMessage("Something went wrong!");
      }
      setOpenSaveDialog(true);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onCancelSaveDialog = () => {
    setOpenSaveDialog(false);
    fetchLeavesHandler(leaveState);
  };

  const activeSwitchHandler = (newValue, index) => {
    leaveState.leaves[index].enabled = newValue;
  }

  const onDelete = async () => {
    let response = await deleteLeaveReason(openDeleteDialog.id);
    if (response.responseCode === 200) {
      dispatch(showToast({ type: 'success', message: `Leave type deleted successfully` }));
      setLeaveState(prev => {
        console.warn('prev leaves state', prev);
        return { ...prev, leaves: prev.leaves.filter(l => l.id !== openDeleteDialog.id) };
      });
      setOpenDeleteDialog(null);
    } else {
      dispatch(showToast({ type: 'warning', message: response.message || `Something went wrong while deleting the leave` }));
    }
    // alert("delete");
  }

  return (
    <>
      <div className={classes.header}>
        <div className={classes.title}>
          <span className={`${leaveState.icon} ${classes.icon}`}></span>
          <span>{leaveState.name}</span>
          <button
            className={classes.newReasonBtn}
            onClick={() => addNewReasonHandler()}
          >
            {"+ " + (leaveState.newButtonLabel ?? "New")}
          </button>
        </div>
        <button className={classes.saveBtn} onClick={() => saveReasonHandler()}>
          Save
        </button>
      </div>
      <div className={classes.body}>
        {leaveState?.leaves.map((element, index) => (
          <div key={index} className={classes.card}>
            <span>Type</span>
            <input
              className={classes.inputForm}
              type="text"
              value={element.name}
              onChange={(e) => handleReasonChange(index, e.target.value)}
            />
            <div className={classes.switchContainer}>
              <SwitchButton
                onChange={(newValue) => {
                  activeSwitchHandler(newValue, index);
                }}
                defaultChecked={element.enabled}
                disabled={!element.id}
              />
              <button onClick={() => setOpenDeleteDialog(element)}>
                <i className="icon-delete"></i>
              </button>
            </div>
          </div>
        ))}
      </div>
      {openSaveDialog && (
        <InformationDialog
          message={message}
          onCancel={onCancelSaveDialog}
        />
      )}
      {openDeleteDialog && <MultiOptionDialog
        message={`Are you sure you want to delete the leave named: "${openDeleteDialog.name}"?`}
        options={[
          { label: 'Delete', callback: () => onDelete(openDeleteDialog) },
          { label: 'Cancel', callback: () => setOpenDeleteDialog(null) },
        ]}
        onClose={() => setOpenDeleteDialog(false)}
      />}
    </>
  );
};

export default LeaveReasonAdmin;
