import React, { useEffect } from "react";
import { Fragment, useContext, useState } from "react";
import classes from "./UserWeek.module.scss";
import userRegisterContext from "../store/user-register-context";
import Filter from "../UI/Filter";
import ShiftsBar from "../shiftShortages/ShiftsBar";
import { useSelector } from "react-redux";
import {
  resourcePermissions,
  checkPermission,
} from "../../features/resourcePepermissionsSlice";
import { selectPendingWeeks } from "../../features/publishSlice";

const UserWeek = ({
  numberOfUsers,
  isFilterOpen,
  searchQuery,
  handleFilterToggle,
  handleSearchChange,
}) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const userRegisterCtx = useContext(userRegisterContext);

  const currentResourcePermissions = useSelector(
    resourcePermissions("week-view")
  );

  const handleSearchToggle = () => {

    setIsSearchOpen(prev =>{
      handleSearchChange({target:{value:""}});
      return !prev;
    });
  };

  // adjust style if pending banner appears
  const pendingWeeks = useSelector(selectPendingWeeks);
  const [isWeekPending, setIsWeekPending] = useState(false);
  useEffect(() => {
    let weekStart = userRegisterCtx?.selectedWeek?.startDate?.toISOString()?.split('T')?.[0] ?? 'no-week';
    setIsWeekPending(pendingWeeks.includes(weekStart));
  }, [pendingWeeks, userRegisterCtx]);


  return (
    <div className={`${classes.UserCalendarContainer} ${isWeekPending ? classes.pendingWeek: ''}`}>
      {checkPermission(currentResourcePermissions, "day-summary") && (
        <ShiftsBar />
      )}
      <div className={classes.week}>
        <div className={classes.userSearchContainer}>
          <div className={classes.userCountInfo}>
            {!isSearchOpen && (
              <Fragment>
                <span className="icon-user" />
                <span className={classes.usersTitle}>Users</span>
                <span className={classes.usersCount}>{numberOfUsers}</span>
              </Fragment>
            )}
            {isSearchOpen && (
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            )}
          </div>
          <div
            className={
              !isSearchOpen
                ? `${classes.searchIconContainer}`
                : `${classes.closeContainer}`
            }
            onClick={handleSearchToggle}
          >
            <span
              className={
                isSearchOpen
                  ? `icon-close ${classes.iconClose}`
                  : `icon-search ${classes.iconSearch}`
              }
            />
          </div>
          <div onClick={handleFilterToggle} className={classes.filterContainer}>
            <span className={"icon-filter"}></span>
          </div>
          {isFilterOpen && <Filter onClose={handleFilterToggle}></Filter>}
        </div>
        <div className={classes.registers}>
          {userRegisterCtx.selectedWeek.week &&
            userRegisterCtx.selectedWeek.week.map((day) => (
              <div
                key={day.id}
                className={`${classes.emptySlot} ${
                  day.isToday === true ? classes.isToday : ""
                }`}
              >
                <span>{day.name}</span>
                <span
                  className={`${
                    day.isToday === true
                      ? classes.weekDaysNumIsToday
                      : classes.weekDaysNum
                  }`}
                >
                  {day.day}
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default UserWeek;