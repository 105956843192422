import { Popover } from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from './AssignSchedules.module.scss';
import Checkbox from "../UI/Checkbox";
import SearchBar from "../UI/SearchBar";

const AssignSchedules = ({ className, site, schedules, enabledSchedules, onOpen, updateSiteSchedules, resetSiteSchedules}) => {

  const [open, setOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [filteredSchedules, setFilteredSchedules] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setFilteredSchedules(schedules.filter(schedule=>`${schedule.label} ${schedule.startTime}-${schedule.endTime}`.toLowerCase().includes(searchQuery)))
  }, [searchQuery, schedules]);

  const onClose = () => setOpen(false);

  const openMenu = (e) => {
    setAnchorElement(e.currentTarget);
    setOpen(true);
  }

  const toggleSchedule = (scheduleId, checked) => {
    const updatedSet = new Set(enabledSchedules);
    checked ? updatedSet.add(scheduleId) : updatedSet.delete(scheduleId);
    updateSiteSchedules(updatedSet, site.id);
  }

  useEffect(() => {
    open ? onOpen(site) : onOpen(null);
  }, [open]);

  const showSearch = schedules.length > 4; 
  const notFound = filteredSchedules.length < 1;

  return (
    <>
      <div
        onClick={openMenu}
        className={`${className} ${open ? 'highlightButton' : ''}`}
      >
        <span className="icon-add-schedule"></span>
      </div>
      <Popover
        id={"skillsContainer"}
        open={open}
        anchorEl={anchorElement}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: { backgroundColor: 'transparent', border: 'none', borderRadius: '7px' }
          }
        }}
      >
        <div className={classes.scheduleContent}>
          {showSearch && <SearchBar onChange={(value) => setSearchQuery(value.toLowerCase())} theme="dark" debounceTime={200}/>}
          {notFound && <h3>No schedules found.</h3>}
          <div className={classes.scheduleList}>
            {filteredSchedules.map((schedule, index) =>
              <div className={`${classes.schedule} ${enabledSchedules.has(schedule.id) ? classes.highlight : ''}`} key={index}>
                <Checkbox checked={enabledSchedules.has(schedule.id) === true} onChange={(checked) => toggleSchedule(schedule.id, checked)} />
                <i className="icon-check-schedule" />
                <div className={classes.scheduleInfo}>
                  <h1>{schedule.label}</h1>
                  <h2>{`${schedule.startTime}-${schedule.endTime}`}</h2>
                </div>
              </div>
            )}
          </div>
          <div className={classes.buttons}>
            {/* ! El cancel debe reiniciar al los schedules iniciales */}
            <button onClick={() => {resetSiteSchedules(site.id); onClose();}}>Cancel</button>
            <button className={classes.green} onClick={onClose}>Done</button>
          </div>
        </div>

      </Popover>
    </>
  );
}

export default AssignSchedules;